import micStyle from './mic.scss'
import speechRecognizer from './speechRecognizer'
import { isIOS, isAndroid, isGoogleChrome, isSafari } from './browserUtils'

const debounce = function (func: (...args: any[]) => void, wait: number, immediate = false) {
  let timeout: number | null

  return function executedFunction(this: any, ...args: any[]) {
    const context = this
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    if (timeout !== null) {
      clearTimeout(timeout)
    }
    timeout = window.setTimeout(later, wait)
    if (callNow) {
      func.apply(context, args)
    }
  }
}

export default class MicBtn extends HTMLElement {
  #recognizer: speechRecognizer | null
  #sendBtn: HTMLElement | null
  #micBtn: HTMLElement | null
  #viewType: 'app' | 'fullscreen' | 'embed'

  static get observedAttributes() {
    return ['data-text', 'is-listening']
  }

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })

    this.speechStart = this.speechStart.bind(this)
    this.handleStart = this.handleStart.bind(this)
    this.handleStop = debounce(this.handleStop.bind(this), 300, true)
    this.handleError = this.handleError.bind(this)
    this.handleResult = debounce(this.handleResult.bind(this), 300, true)
    this.handleSoundEnd = this.handleSoundEnd.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.gracefulRestart = this.gracefulRestart.bind(this)

    this.#recognizer = null
    this.#sendBtn = null
    this.#micBtn = null
    this.#viewType = 'fullscreen'
  }

  connectedCallback() {
    if (this.shadowRoot !== null) {
      const viewType = this.getAttribute('view-type')
      if (viewType !== null && ['app', 'fullscreen', 'embed'].includes(viewType)) {
        this.#viewType = viewType as 'app' | 'fullscreen' | 'embed'
      }

      // DOM
      this.shadowRoot.innerHTML = this.template()
      this.#sendBtn = this.shadowRoot.getElementById('fab-send-btn')
      this.#micBtn = this.shadowRoot.getElementById('fab-mic-btn')

      const inputText = this.getAttribute('data-text')

      if (isGoogleChrome() || isSafari()) {
        this.#recognizer = new speechRecognizer()
        this.setupRecognizerEvents()
        this.#recognizer.init()
        if (this.#sendBtn !== null && this.#micBtn !== null) {
          this.#sendBtn.classList.remove('is-light')
          if (inputText !== null && inputText.trim() !== '') {
            this.#sendBtn.style.display = 'block'
            this.#micBtn.style.display = 'none'
            this.#sendBtn.classList.add('is-light')
          } else {
            this.#sendBtn.style.display = 'none'
            this.#micBtn.style.display = 'block'
          }
        }
      } else if (this.#viewType === 'app') {
        if (this.#sendBtn !== null && this.#micBtn !== null) {
          this.#sendBtn.style.display = 'none'
          this.#micBtn.style.display = 'block'
        }
      } else {
        if (this.#sendBtn !== null && this.#micBtn !== null) {
          this.#sendBtn.style.display = 'block'
          this.#micBtn.style.display = 'none'
        }
      }

      // Style
      const styleElement = document.createElement('style')
      styleElement.appendChild(document.createTextNode(micStyle))
      this.shadowRoot.appendChild(styleElement)

      // Event
      if (this.#micBtn !== null) {
        this.#micBtn.addEventListener('click', this.speechStart)
      }
      if (this.#sendBtn !== null) {
        this.#sendBtn.addEventListener('click', this.sendMessage)
      }
    }
  }

  disconnectedCallback() {
    if (this.shadowRoot !== null) {
      if (this.#micBtn !== null) {
        this.#micBtn.removeEventListener('click', this.speechStart)
      }
      if (this.#sendBtn !== null) {
        this.#sendBtn.removeEventListener('click', this.sendMessage)
      }
    }
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    // console.log(`Attribute ${name} has changed. ${oldValue} => ${newValue}`)
    // 透過 attribute 修改顯示狀態
    if (['data-text', 'is-listening'].includes(name)) {
      this.checkInputContent()
    }
  }

  checkInputContent() {
    if (isGoogleChrome() || isSafari() || this.#viewType === 'app') {
      const inputText = this.getAttribute('data-text')
      if (inputText !== null && this.#micBtn !== null && this.#sendBtn !== null) {
        if (inputText.trim() === '') {
          this.#sendBtn.classList.remove('is-light')
          this.#sendBtn.style.display = 'none'
          this.#micBtn.style.display = 'block'
        } else {
          this.#micBtn.style.display = 'none'
          this.#sendBtn.style.display = 'block'
          this.#sendBtn.classList.add('is-light')
        }
      }
    }
  }

  setupRecognizerEvents() {
    if (this.#recognizer !== null) {
      this.#recognizer.addEventListener('asr-start', this.handleStart)
      this.#recognizer.addEventListener('asr-stop', this.handleStop)
      this.#recognizer.addEventListener('asr-error', this.handleError)
      this.#recognizer.addEventListener('asr-result', this.handleResult)
      this.#recognizer.addEventListener('asr-soundend', this.handleSoundEnd)
    }
  }

  speechStart() {
    if (this.#viewType === 'app') {
      // TODO: dispatch speech start event
      this.dispatchEvent(new CustomEvent('app-speech'))
    } else if (this.#recognizer !== null) {
      console.log('Speech recognition start')
      // perform speech recognition
      this.#recognizer.speech()
    } else {
      // TODO: fallback to backend server
    }
  }

  speechStop() {
    if (this.#viewType !== 'app' && this.#recognizer !== null) {
      this.#recognizer.abort()
    }
  }

  sendMessage() {
    const inputText = this.getAttribute('data-text')
    if (inputText !== null && inputText.trim() !== '') {
      this.dispatchEvent(new CustomEvent('send-msg'))
    } else {
      this.dispatchEvent(new CustomEvent('clear-input'))
    }
  }

  handleStart(event: Event) {
    this.dispatchEvent(new CustomEvent('web-speech'))
  }

  handleStop(event: Event) {
    this.dispatchEvent(new CustomEvent('web-speech-stop'))
  }

  handleError(event: Event) {
    const e = event as CustomEvent
    if (e.detail.error === 'service-not-allowed' && isSafari()) {
      e.detail.detail =
        '您的瀏覽器不支援或不允許使用您的麥克風 (Safari 瀏覽器需要啟用 Siri 服務才能使用您的麥克風)'
    }
    this.dispatchEvent(
      new CustomEvent('web-speech-error', {
        detail: e.detail
      })
    )
  }

  handleResult(event: Event) {
    // if (this.#textField !== null) {
    //   console.log(this.#textField)
    //   console.log(this.#textField.value)
    //   console.log((event as CustomEvent).detail.result)
    //   this.#textField.value = `${(event as CustomEvent).detail.result}`
    // }
    // TODO: dispatch text result with event
    // console.log(event, `${(event as CustomEvent).detail.result}`)
    this.dispatchEvent(
      new CustomEvent('asr-result', {
        detail: {
          result: (event as CustomEvent).detail.result
        }
      })
    )
  }

  handleSoundEnd() {
    this.dispatchEvent(
      new CustomEvent('asr-soundend')
    )
  }

  gracefulRestart() {
    this.#recognizer?.gracefulRestart()
  }

  template() {
    return `
      <div id="fab-send-btn" class="fab">
        <i class="zmdi zmdi-mail-send"></i>
      </div>
      <div id="fab-mic-btn" class="fab">
        <i class="zmdi zmdi-mic"></i>
      </div>
    `
  }
}
