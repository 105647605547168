export default {
  'no-speech': '沒有偵測到您的聲音，請嘗試說出服務或功能名稱',
  aborted:
    'Speech input was aborted in some manner, perhaps by some user-agent-specific behavior like a button the user can press to cancel speech input.',
  'audio-capture': 'Audio capture failed.',
  network: '您的網路連線不穩，暫時無法使用語音輸入',
  'not-allowed': '您未授權使用您的麥克風',
  'service-not-allowed':
    "您的瀏覽器不支援或不允許使用您的麥克風",
  'bad-grammar':
    'There was an error in the speech recognition grammar or semantic tags, or the chosen grammar format or semantic tag format was unsupported.',
  'language-not-supported': 'The language was not supported.'
}
