import Header from './wcHeader'
import Button from './wcSimpleButton'
import Tooltip from './wcTooltip'
import FontSizeBtn from './wcFontSizeBtn'
import AppBtn from './wcAppBtn'
import MicBtn from './wcMicBtn/wcMicBtn'

const registerElements = function () {
  // Register custom elements
  if (customElements.get('chtapp-page-header') === undefined) {
    window.customElements.define('chtapp-page-header', Header)
  }
  if (customElements.get('chtapp-button') === undefined) {
    window.customElements.define('chtapp-button', Button)
  }
  if (customElements.get('chtapp-tooltip') === undefined) {
    window.customElements.define('chtapp-tooltip', Tooltip)
  }
  if (customElements.get('chtapp-fontsize-popup') === undefined) {
    window.customElements.define('chtapp-fontsize-popup', FontSizeBtn)
  }
  if (customElements.get('chtapp-appbtn') === undefined) {
    window.customElements.define('chtapp-appbtn', AppBtn)
  }
  if (customElements.get('chtapp-mic-btn') === undefined) {
    window.customElements.define('chtapp-mic-btn', MicBtn)
  }
}

export { Header as PageHeader, Button as SimpleButton, Tooltip, FontSizeBtn, MicBtn }

export default registerElements
