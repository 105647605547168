import speechError from './speechError'
import { isAndroid } from './browserUtils'

enum speechErrorType {
  noSpeech = 'no-speech',
  aborted = 'aborted',
  audioCapture = 'audio-capture',
  network = 'network',
  notAllowed = 'not-allowed',
  serviceNotAllowed = 'service-not-allowed',
  badGrammar = 'bad-grammar',
  languageNotSupported = 'language-not-supported'
}

// throw error if not grant within 30 seconds
// const grantTimeout = 30

function isEdge(): boolean {
  return window.navigator.userAgent.indexOf('Edg') > -1
}

// class ASRError extends Error {
//   constructor(message: string) {
//     super(message)
//     this.name = 'ValidationError'
//   }
// }

export default class speechRecognizer extends EventTarget {
  // grantTimer: number | null
  SpeechRecognition: SpeechRecognition
  isSpeeching: boolean
  recognition: SpeechRecognition | null

  constructor() {
    super()

    this.init = this.init.bind(this)

    // this.grantTimer = null
    this.isSpeeching = false
    this.recognition = null
  }

  init() {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    if (typeof SpeechRecognition === 'function') {
      this.recognition = new SpeechRecognition()
      this.recognition.lang = 'cmn-Hant-TW'
      this.recognition.continuous = true
      if (isAndroid()) {
        this.recognition.interimResults = false
      } else {
        this.recognition.interimResults = true
      }
      this.recognition.maxAlternatives = 1

      this.recognition.onstart = this.#onstart.bind(this)
      this.recognition.onend = this.#onend.bind(this)
      this.recognition.onspeechstart = this.#onspeechstart.bind(this)
      this.recognition.onspeechend = this.#onspeechend.bind(this)
      this.recognition.onaudioend = this.#onaudioend.bind(this)
      this.recognition.onsoundend = this.#onsoundend.bind(this)
      this.recognition.onresult = this.#onresult.bind(this)
      this.recognition.onerror = this.#onerror.bind(this)
    } else {
      this.#callError(speechErrorType.serviceNotAllowed)
    }
  }

  #callError(msg: speechErrorType) {
    if (this.recognition) {
      this.recognition.stop()
    }
    const errorMsgs = speechError as { [key: string]: string }
    if (msg in errorMsgs) {
      this.dispatchEvent(
        new CustomEvent('asr-error', {
          detail: {
            error: msg,
            detail: errorMsgs[msg]
          }
        })
      )
    } else {
      this.dispatchEvent(
        new CustomEvent('asr-error', {
          detail: {
            error: msg,
            detail: msg
          }
        })
      )
    }
    this.isSpeeching = false
  }

  #onstart() {
    // if (this.grantTimer !== null) {
    //   clearTimeout(this.grantTimer)
    // }
    this.dispatchEvent(new CustomEvent('asr-start'))
  }

  #onend() {
    if (isAndroid() && this.isSpeeching) {
      if (this.recognition !== null) {
        const _self = this
        setTimeout(function () {
          _self.recognition.start()
        }, 200)
      }
    } else {
      this.isSpeeching = false
    }
  }

  #onspeechstart() {}

  #onspeechend() {
    if (isAndroid() && this.isSpeeching) {
      this.isSpeeching = true
      if (this.recognition !== null) {
        const _self = this
        setTimeout(function () {
          _self.recognition.start()
        }, 200)
      }
    } else {
      this.dispatchEvent(new CustomEvent('asr-stop'))
    }
  }

  #onaudioend() {}

  #onsoundend() {
    this.dispatchEvent(new CustomEvent('asr-soundend'))
  }

  // This runs when the speech recognition service returns result
  #onresult(event: SpeechRecognitionEvent) {
    // let finalTranscript = ''
    // 確保是最終結果
    // if (event.results[event.results.length - 1].isFinal) {
    if (event.results.length > 0) {
      const finalTranscript = event.results[event.results.length - 1][0].transcript
      if (finalTranscript.trim() !== '') {
        this.dispatchEvent(
          new CustomEvent('asr-result', {
            detail: {
              result: finalTranscript
            }
          })
        )
        if (event.results[event.results.length - 1].isFinal) {
          this.dispatchEvent(new CustomEvent('asr-soundend'))
        }
      }
    }
    // }
  }

  #onerror(event: SpeechRecognitionErrorEvent) {
    this.#callError(event.error as speechErrorType)
  }

  speech() {
    if (!this.isSpeeching) {
      try {
        this.isSpeeching = true
        if (this.recognition !== null) {
          this.recognition.start()
        }
      } catch (err: any) {
        this.#callError(err.toString())
      }
      // this.grantTimer = setTimeout(() => {
      //   this.#callError(speechErrorType.serviceNotAllowed)
      // }, grantTimeout * 1000)
    }
  }

  abort() {
    this.dispatchEvent(new CustomEvent('asr-stop'))
    this.isSpeeching = false
    if (this.recognition !== null) {
      this.recognition.abort()
    }
  }

  gracefulRestart() {
    if (this.isSpeeching) {
      if (this.recognition !== null) {
        this.recognition.abort()
      }
    }
  }
}
