function isBrave(): boolean {
  if (window.navigator.brave != undefined) {
    if (window.navigator.brave.isBrave.name == 'isBrave') {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

export function isAndroid() {
  const u = navigator.userAgent
  return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
}

export function isGoogleChrome() {
  const isChromium = window.chrome
  const winNav = window.navigator
  const vendorName = winNav.vendor
  const isOpera = typeof window.opr !== 'undefined'
  const isIEedge = winNav.userAgent.indexOf('Edg') > -1
  const isIOSChrome = winNav.userAgent.match('CriOS')

  if (isIOSChrome) {
    // console.log('is Google Chrome on IOS')
    return false
  } else if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false &&
    isBrave() === false
  ) {
    // console.log('is Google Chrome')
    return true
  } else {
    return false
  }
}

export function isSafari() {
  const u = navigator.userAgent
  const chromeAgent = u.indexOf('Chrome') > -1
  const safariAgent = u.indexOf('Safari') > -1
  return !chromeAgent && safariAgent
}
